var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
      _c("div", [
        _c(
          "section",
          { class: [_vm.isFullScreen ? "container-is-fs" : ""] },
          [_c("pdf-renderer", { attrs: { isDocument: true } })],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }